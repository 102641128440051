import React, { useEffect } from 'react';
import { useFrogContext } from './LandingPage';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer';
import {
    Container,
    Grid,
    Button,
    CardActionArea,
    CardActions,
    CardMedia,
    CardContent,
    Box,
    Typography,
    Divider,
    Card,
    useMediaQuery
} from '@mui/material';

import section6 from '../../assets/images/section6.png';
import section6_2 from '../../assets/images/section6_2.png';

function Section6() {
    const theme = useTheme();
    const xsMatched = useMediaQuery(theme.breakpoints.down('xs'));
    const smMatched = useMediaQuery(theme.breakpoints.down('sm'));
    const mdMatched = useMediaQuery(theme.breakpoints.down('md'));

    const { sec6_viewed, setSec6_viewed } = useFrogContext();
    const { ref: froggyRef, inView: froggy } = useInView();
    const { ref: btn1Ref, inView: btn1 } = useInView();
    const { ref: btn2Ref, inView: btn2 } = useInView();
    const { ref: btn3Ref, inView: btn3 } = useInView();
    const { ref: btn4Ref, inView: btn4 } = useInView();
    const { ref: btn5Ref, inView: btn5 } = useInView();
    const { ref: lastFroggyRef, inView: lastFroggy } = useInView();
    const { ref: lastTextRef, inView: lastText } = useInView();

    const { viewed, setViewed } = useFrogContext();

    const btnStyle = {
        padding: '8px 30px',
        borderRadius: '4px',
        border: '1px 0px 0px 0px',
        opacity: '0px',
        background: '#387575',
        color: '#ffffff',
        letterSpacing: '1px',
        fontSize: '16px',
        fontWeight: 'regular',
        margin: smMatched ? '10px 15px' : '5px 35px',
        minWidth: smMatched ? '110px' : ''
    };

    useEffect(() => {
        setTimeout(() => {
            if (lastText) {
                setSec6_viewed({
                    ...sec6_viewed,
                    sec6_froggy: true,
                    sec6_btn1: true,
                    sec6_btn2: true,
                    sec6_btn3: true,
                    sec6_btn4: true,
                    sec6_btn5: true,
                    sec6_lastFroggy: true,
                    sec6_lastText: true
                });
            }
        }, [2000]);
    }, [froggy, btn1, btn2, btn3, btn4, btn5, lastFroggy, lastText]);

    return (
        <Container
            sx={{
                // height: '100dvh',
                maxWidth: '100%',
                padding: '0px',
                margin: '0px',
                marginBottom: smMatched ? '50px' : '20px'
            }}
        >
            <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        ref={froggyRef}
                        className={`${
                            froggy && !sec6_viewed.sec6_froggy && !smMatched
                                ? 'aniSection6Froggy'
                                : ''
                        }`}
                    >
                        <img
                            src={section6}
                            width={smMatched ? '300px' : '410px'}
                            alt="froggy"
                        />
                    </Box>
                </Grid>

                {/* <Grid item xs={12} align="center">
                    <Button
                        ref={btn1Ref}
                        className={`${
                            btn1 && !sec6_viewed.sec6_btn1 && !smMatched
                                ? 'aniSection6Btn1'
                                : ''
                        }`}
                        style={btnStyle}
                        onClick={() => {
                            window.open('https://t.me/froggymeme', '_blank');
                        }}
                    >
                        TELEGRAM
                    </Button>
                    <Button
                        ref={btn2Ref}
                        className={`${
                            btn2 && !sec6_viewed.sec6_btn2 && !smMatched
                                ? 'aniSection6Btn2'
                                : ''
                        }`}
                        style={btnStyle}
                        onClick={() => {
                            window.open(
                                'https://twitter.com/froggymeme77',
                                '_blank'
                            );
                        }}
                    >
                        TWITTER
                    </Button>
                    <Button
                        ref={btn3Ref}
                        className={`
                    ${
                        btn3 && !sec6_viewed.sec6_btn3 && !smMatched
                            ? 'aniSection6Btn3'
                            : ''
                    }
                `}
                        style={btnStyle}
                    >
                        AIRDROP
                    </Button>
                    <Button
                        ref={btn4Ref}
                        className={`${
                            btn4 && !sec6_viewed.sec6_btn4 && !smMatched
                                ? 'aniSection6Btn4'
                                : ''
                        }`}
                        style={btnStyle}
                    >
                        FAIRLAUNCH
                    </Button>
                    <Button
                        ref={btn5Ref}
                        className={`${
                            btn5 && !sec6_viewed.sec6_btn5 && !smMatched
                                ? 'aniSection6Btn5'
                                : ''
                        }`}
                        style={btnStyle}
                    >
                        CONTRACT ADDRESS
                    </Button>
                </Grid> */}

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={12}
                    // align="center"
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                    // zIndex="-1"
                >
                    <Button
                        ref={btn1Ref}
                        style={btnStyle}
                        className={`${
                            btn1 && !viewed?.sec1_btn1 && !smMatched
                                ? 'aniSection6Btn1'
                                : ''
                        }`}
                        onClick={() => {
                            window.open('https://t.me/froggymeme', '_blank');
                        }}
                    >
                        TELEGRAM
                    </Button>
                    <Button
                        ref={btn2Ref}
                        style={btnStyle}
                        className={`${
                            btn2 && !viewed?.sec1_btn2 && !smMatched
                                ? 'aniSection6Btn2'
                                : ''
                        }`}
                        onClick={() => {
                            window.open(
                                'https://twitter.com/froggymeme77',
                                '_blank'
                            );
                        }}
                    >
                        TWITTER
                    </Button>
                    <Button
                        ref={btn3Ref}
                        style={btnStyle}
                        className={`${
                            btn3 && !viewed?.sec1_btn3 && !smMatched
                                ? 'aniSection6Btn3'
                                : ''
                        }`}
                    >
                        AIRDROP
                    </Button>
                    <Button
                        ref={btn4Ref}
                        style={btnStyle}
                        className={`${
                            btn4 && !viewed?.sec1_btn4 && !smMatched
                                ? 'aniSection6Btn4'
                                : ''
                        }`}
                    >
                        FAIRLAUNCH
                    </Button>
                    <Button
                        ref={btn5Ref}
                        style={btnStyle}
                        className={`${
                            btn5 && !viewed?.sec1_btn5 && !smMatched
                                ? 'aniSection6Btn5'
                                : ''
                        }`}
                    >
                        CONTRACT ADDRESS
                    </Button>
                </Grid>

                <Grid
                    item
                    xs={12}
                    ref={lastFroggyRef}
                    className={`${
                        lastFroggy && !sec6_viewed.sec6_lastFroggy && !smMatched
                            ? 'aniSection6LastFroggy'
                            : ''
                    }`}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <img src={section6_2} width="210px" alt="froggy" />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            ref={lastTextRef}
                            className={`${
                                lastText &&
                                !sec6_viewed.sec6_lastText &&
                                !smMatched
                                    ? 'aniSection6LastText'
                                    : ''
                            }`}
                            style={{
                                width: smMatched ? '300px' : '420px',
                                fontSize: smMatched ? '14px' : '16px',
                                color: '#FFFFFf',
                                textAlign: 'center'
                            }}
                        >
                            $FROGGY WAS MADE FOR A FAN AND HAS NO VALUE. DON’T
                            RISK MONEY YOU ARE AFRAID OF LOSING. THE PRICE MAY
                            GO UP OR IT MAY GO DOWN. WE ARE NOT RESPONSIBLE FOR
                            THE PRICE OF THE TOKEN.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Section6;
